import React from 'react';

import { ThemeProvider } from 'styled-components';
import { themeConfigs, themeNames } from '@upgrade/themes';
import GlobalStyles from '@upgrade/styles/GlobalStyles';
import { SkipToContent } from '@upgrade/react-components';

// eslint-disable-next-line import/prefer-default-export
export const wrapStyles = ({ element }) => {
  // "document" is not available during server side rendering.
  const themeParam =
    typeof document === 'undefined' || !document.location
      ? 'upgrade'
      : new URL(document.location).searchParams.get('theme');

  const theme = themeConfigs[themeParam] || themeConfigs[themeNames.UNIVERSAL_CREDIT_THEME_NAME];

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SkipToContent />
      {element}
    </ThemeProvider>
  );
};
